
import {
  IonApp, IonButton,
  IonContent,
  IonMenu,
  IonNote,
  IonRouterOutlet,
  IonSplitPane, menuController, IonIcon, IonLabel, IonItem, IonList, toastController, IonText
} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import StorageProvider from '@/utils/storage'
import {settings} from 'ionicons/icons';
import {ModeTypeCodes, StorageAttributes, appVersion} from "@/enum";
import SettingsComponent from "@/components/SettingsComponent.vue";
import { close } from 'ionicons/icons';
export default defineComponent({
  name: 'App',
  components: {
    SettingsComponent,
    IonApp,
    IonContent,
    IonMenu,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonButton,
    IonIcon,
    IonLabel, IonItem, IonList, IonText
  },
  setup() {
    const router = useRouter();
    // const a = getPlatforms(window);
    // console.log(a);
    const showInstall = ref(false);
    let deferredPrompt;

    // reactive theme changes
    const themeMatchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    const isDark = ref(themeMatchMedia.matches);
    themeMatchMedia.addListener(() => isDark.value = themeMatchMedia.matches);

    // ios safari swipe gestures disable
    // const element = document.getElementById("app");
    // element.addEventListener('touchstart', (e) => {
    //   // prevent swipe to navigate gesture
    //   e.preventDefault();
    // });


    // const selectedIndex = ref(0);
    // const appPages = [
    //   {
    //     title: 'Home',
    //     url: '/',
    //     iosIcon: home,
    //     mdIcon: home
    //   },
    //   {
    //     title: 'Scan',
    //     url: '/scan',
    //     iosIcon: scanOutline,
    //     mdIcon: mailSharp
    //   },
    // ];

    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }

    onMounted(async () => {
      window.addEventListener('beforeinstallprompt', (e) => {
        showInstall.value = true;
        deferredPrompt = e;
      });

      const query = useRoute().query
      let resetToBaseUrl = false;
      if (query[StorageAttributes.FDEUTOKEN]) {
        resetToBaseUrl = true;
        StorageProvider.set(StorageAttributes.FDEUTOKEN, query[StorageAttributes.FDEUTOKEN]);
      }
      if (query[StorageAttributes.TIRETOKEN]) {
        resetToBaseUrl = true;
        StorageProvider.set(StorageAttributes.TIRETOKEN, query[StorageAttributes.TIRETOKEN]);
      }
      if (query[StorageAttributes.MODE]) {
        resetToBaseUrl = true;
        const mode = query[StorageAttributes.MODE].toString().toUpperCase()
        if ([ModeTypeCodes.AUT.toString(), ModeTypeCodes.DEU.toString()].includes(mode)) {
          StorageProvider.set(StorageAttributes.MODE, mode);
        }
      }
      if (resetToBaseUrl) {
        await router.replace('/');
      }

      // 1s timeout for better UX
      setTimeout(async () => {
        const updated = await StorageProvider.get(StorageAttributes.APPUPDATED)
        if (updated) {
          StorageProvider.remove(StorageAttributes.APPUPDATED);
          openUpdateToast();
        } else {
          checkDemo();
        }
      }, 1000);
    });

    async function openUpdateToast() {
      const toast = await toastController
        .create({
          position: "bottom",
          message: "App Update auf Version " + appVersion + " erfolgreich",
          color: "primary",
          duration: 5000,
          buttons: [
            {
              icon: close,
              role: 'cancel',
              handler: () => { }
            }
          ],
        })
      await toast.present();
    }

    async function checkDemo() {
      const showDemoSlides = await StorageProvider.get(StorageAttributes.SHOWDEMOSLIDES);
      if (!showDemoSlides) {
        await router.push("/demoSlides");
      }
    }

    async function install() {
      if (deferredPrompt != null) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          deferredPrompt = null;
        }
      }
    }

    return {
      menuController,
      install,
      showInstall,
      isDark,
      settings,
      version: appVersion,
      notLiveStage: ['raeder123-test.kleenecode.com', 'localhost', '127.0.0.1'].includes(location.hostname),
    }
  }
});
