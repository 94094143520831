export enum StorageAttributes {
  FDEUTOKEN = "fdeu-token",
  TIRETOKEN = "tire-token",
  MODE = "mode",
  SHOWDEMOSLIDES = "showDemoSlides",
  MYSCANSFILTER = "myScansFilter",
  SESSIONGUID = "sessionGuid",
  APPUPDATED = "appUpdated",
}

export enum ModeTypeCodes {
  DEU = "DEU",
  AUT = "AUT"
}

export enum ImageTypeCodes {
  FDEU = "FDEU",
  FAUT = "FAUT",
  FAUTC = "FAUTC",
  TIRE = "TIRE",
}

interface ImageTypeEntry {
  text: string,
  country?: string,
  ratio: number,
  minDimension: number,
  src: string,
  code: string,
}

interface ImageTypeWrapper {
  FDEU: ImageTypeEntry,
  FAUT: ImageTypeEntry,
  FAUTC: ImageTypeEntry,
  TIRE: ImageTypeEntry
}

export const ImageTypes: ImageTypeWrapper = {
  FDEU: {
    text: "Zulassungsbescheinigung Teil 1",
    country: "Deutschland",
    ratio: 210/105,
    minDimension: 2400, //2400x1200
    src: "assets/img/fdeu.jpg",
    code: "FDEU",
  },
  FAUT: {
    text: "Zulassungsbescheinigung Teil 1",
    country: "Österreich",
    ratio: 105/297,
    minDimension: 3000, //1200x3394, Tichelmann: -10% = 3054 + gerundet
    src: "assets/img/faut.jpg",
    code: "FAUT",
  },
  FAUTC: {
    text: "Zulassungsbescheinigung Teil 1 - Karte",
    country: "Österreich",
    ratio: 86/54,
    minDimension: 1600, //Tichelmann: ok
    src: "assets/img/fautc.png",
    code: "FAUTC",
  },
  TIRE: {
    text: "Reifen Seitenprofil",
    ratio: 1,
    minDimension: 1600, //Tichelmann: ok
    src: "assets/img/tire.jpg",
    code: "TIRE",
  },
}

export const appVersion = "2.1.1"
