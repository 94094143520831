
import {defineComponent, onMounted, ref} from 'vue';
import {
  IonModal,
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonLabel,
  IonInput,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonList,
  IonListHeader,
  IonContent,
  alertController,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle
} from '@ionic/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import StorageProvider from "@/utils/storage";
import {ModeTypeCodes, StorageAttributes} from "@/enum";
import {first, Subscription} from "rxjs";
import {db} from "@/utils/db";
import {trash} from 'ionicons/icons';

export default defineComponent({
  name: 'SettingsComponent',
  components: {
    IonModal, IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonButtons, IonButton, IonTitle, IonToolbar, IonHeader, IonLabel, IonInput, IonItem, IonRadio, IonRadioGroup, IonList, IonListHeader, IonContent, IonIcon
  },
  setup() {
    let fdeuToken = ref();
    let tireToken = ref();
    let mode = ref();

    const presentAlert = async () => {
      const alert = await alertController
        .create({
          backdropDismiss: false,
          header: 'Alle Scans löschen',
          message: 'Möchtest du wirklich alle Scans löschen?',
          buttons: [
            {
              text: 'Abbrechen'
            },
            {
              text: 'Alle löschen',
              handler : () => {
                deleteAllScans();
              }
            }
          ]
        });
      await alert.present();
    }

    function cancel() {
      this.$refs.modal.$el.dismiss();
    }

    function confirm() {
      const fdeuTokenInput = this.$refs.fdeuTokenInput.$el.value;
      const tireTokenInput = this.$refs.tireTokenInput.$el.value;
      const modeInput = this.$refs.radioGroup.$el.value

      StorageProvider.set(StorageAttributes.MODE, modeInput);
      StorageProvider.set(StorageAttributes.FDEUTOKEN, fdeuTokenInput);
      StorageProvider.set(StorageAttributes.TIRETOKEN, tireTokenInput);

      this.$refs.modal.$el.dismiss();
    }

    function onWillDismiss() {
    }

    function onWillPresent() {
      StorageProvider.getFdeuToken$().pipe(first()).subscribe(value => {
        fdeuToken.value = value;
      });
      StorageProvider.getTireToken$().pipe(first()).subscribe(value => {
        tireToken.value = value;
      });
      StorageProvider.getMode$().pipe(first()).subscribe(value => {
        mode.value = value;
      });
    }

    function deleteAllScans() {
      db.myScans.clear();
    }

    return {
      confirm,
      cancel,
      ModeTypeCodes,
      tireToken,
      fdeuToken,
      mode,
      onWillDismiss,
      onWillPresent,
      presentAlert,
      trash,
    }
  },
});
