import {Drivers, Storage} from "@ionic/storage";
import {BehaviorSubject} from "rxjs";
import {StorageAttributes} from "@/enum";

class StorageProvider {
  private _storage: Storage;
  private fdeuToken$: BehaviorSubject<string>;
  private tireToken$: BehaviorSubject<string>;
  private mode$: BehaviorSubject<string>;
  private myScansFilter$: BehaviorSubject<string[]>

  constructor() {
    this._storage = new Storage({
      name: 'main',
      driverOrder: [Drivers.LocalStorage]
    });
    this._storage.create().then(() => this.load());
  }

  async load() {
    this._storage.get(StorageAttributes.FDEUTOKEN).then(token => this.fdeuToken$ = new BehaviorSubject<string>(token));
    this._storage.get(StorageAttributes.TIRETOKEN).then(token => this.tireToken$ = new BehaviorSubject<string>(token));
    this._storage.get(StorageAttributes.MODE).then(mode => this.mode$ = new BehaviorSubject<string>(mode));
    this._storage.get(StorageAttributes.MYSCANSFILTER).then(filter => {
      if (Array.isArray(filter)){
        this.myScansFilter$ = new BehaviorSubject<string[]>(filter)
      } else {
        this.myScansFilter$ = new BehaviorSubject<string[]>([])
      }
    });
    this._storage.get(StorageAttributes.SESSIONGUID).then(guid => {
      if (guid == null) {
        guid = crypto.randomUUID();
        this.set(StorageAttributes.SESSIONGUID, guid);
      }
    })
  }

  set(key, value) {
    this._storage.set(key,  value);
    if(key == StorageAttributes.FDEUTOKEN) {
      this.fdeuToken$.next(value);
    } else if (key == StorageAttributes.TIRETOKEN) {
      this.tireToken$.next(value);
    } else if (key == StorageAttributes.MODE) {
      this.mode$.next(value);
    } else if (key == StorageAttributes.MYSCANSFILTER) {
      this.myScansFilter$.next(value);
    }
  }

  get(key) {
    return this._storage.get(key);
  }

  remove(key) {
    return this._storage.remove(key);
  }

  getFdeuToken$(): BehaviorSubject<string> {
    return this.fdeuToken$;
  }

  getTireToken$(): BehaviorSubject<string> {
    return this.tireToken$;
  }

  getMode$(): BehaviorSubject<string> {
    return this.mode$;
  }

  getMyScansFilter$(): BehaviorSubject<string[]> {
    return this.myScansFilter$;
  }
}

export default new StorageProvider();
