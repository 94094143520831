import Dexie, { Table } from 'dexie';

export interface MyScan {
    id?: number;
    apiId?: string;
    name: string;
    typeCode: string;
    scannedImage: string;
    classifiedData?: JSON;
    description?: string;
    // unrolledImage?: string;
    unrolledBoxImage?: string;
    // label?: string;
}

export class MySubClassedDexie extends Dexie {
    myScans!: Table<MyScan>;

    constructor() {
        super('raeder123');
        this.version(1).stores({
            myScans: '++id' // Primary key and indexed props
        });
    }
}

export const db = new MySubClassedDexie();

db.version(2).stores({myScans: "++id"}).upgrade(tx => {
  return tx.table("myScans").toCollection().modify(myScan => {
    console.log('dexie IDB migration')
    console.log('legacy:', myScan);
    if (myScan.typeCode == null) {
      myScan.typeCode = myScan.name.substring(myScan.name.indexOf('_') + 1, myScan.name.indexOf('.'));
      delete myScan.type;
    }
    if (myScan.classifiedData != null) {
      try {
        myScan.classifiedData = JSON.parse(myScan.classifiedData);
      } catch (e) {
        console.warn(e);
      }
    }
    console.log('upgraded:', myScan);
  });
});
